<template>
  <div class="main--body--content gf_col-sm-12 gf_col-md-12">
    <div class="back-menu align-center">
      <router-link :to="{ name: 'Docs' }">
        <VIcon class="mr-2" dense>fa-caret-left</VIcon>
        <span>Docs</span>
      </router-link>
    </div>

    <VRow v-if="!loadingData">
      <VCol cols="6">
        <VAutocomplete
          outlined
          dense
          v-model="form.category_id"
          :items="getCategoriesDropdown"
          item-value="id"
          item-text="title"
          label="Choose a category"
        ></VAutocomplete>
      </VCol>
      <VCol cols="6">
        <VTextField
          outlined
          dense
          label="Title"
          placeholder="Enter the title"
          v-model="form.title"
          :required="true"
        />
      </VCol>
      <VCol cols="6">
        <VTextField
          outlined
          dense
          label="Description"
          placeholder="Enter the description, it will help better searcb"
          v-model="form.description"
        />
      </VCol>
      <VCol cols="6">
        <VTextField
          outlined
          dense
          type="number"
          label="Position"
          placeholder="1.0000000"
          v-model.number="form.position"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <InputImage
          title="File Uploader"
          placeholder="https://uploadcare.com/jasf9028qitws/asfl;asf/3000x3000/light"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <h3 class="mb-2">GemPages Markdown Editor</h3>
        <div :id="id" class="gem-md-editor" />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div v-if="warning && warning.length">
          <p v-for="(warn, key) in warning" :key="key">{{ warn }}</p>
        </div>
        <VBtn
          color="primary"
          class="text-normal"
          type="button"
          @click.prevent="updateArticle"
        >
          <VIcon class="mr-2" dense>fa-save</VIcon>
          <span>Save</span>
        </VBtn>
      </VCol>
    </VRow>

    <Modal
      v-if="showModal.checkVersion"
      cClass="gf_padding-20"
      mClass
      hClass="gf_flex gf_flex-wrap"
      @cancel="closeModal"
    >
      <template v-slot:header>
        <h3>Update Alert</h3>
      </template>
      <template v-slot:default>
        <div class="gf_col-md-12">
          <h4>You have {{ criticalChanges.length }} critical changes in:</h4>
          <ul class="gf_list">
            <li
              class="gf_list-item"
              :key="ind"
              v-for="(item, ind) in criticalChanges"
            >
              {{ item }} field.
            </li>
          </ul>
          <h4>Do you want to create new version?</h4>
          <div class="gf_btn-group gf_mt-20">
            <div class="gf_btn gf_btn-secondary" @click="doUpdate(submitForm)">
              Update only
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import docsAPI from "@/api/internal/docs.js";
import timeago from "@/plugins/timeago";

import "@toast-ui/editor/dist/toastui-editor.css";
import Editor from "@toast-ui/editor";
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import 'prismjs/themes/prism.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js';
import defaultOptions from "./default-options";

export default {
  name: "ArticleEditor",
  props: {
    articleId: {
      type: [String, Number]
    },
    value: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      required: false,
      default() {
        return (
          "markdown-editor-" +
          +new Date() +
          ((Math.random() * 1000).toFixed(0) + "")
        );
      }
    },
    options: {
      type: Object,
      default() {
        return defaultOptions;
      }
    },
    mode: {
      type: String,
      default: "markdown"
    },
    height: {
      type: String,
      required: false,
      default: "600px"
    },
    language: {
      type: String,
      required: false,
      default: "en_US" // https://github.com/nhnent/tui.editor/tree/master/src/js/langs
    }
  },
  components: {},

  data() {
    return {
      form: {
        articleId: null,
        title: null,
        description: null,
        position: null
      },
      cacheForm: {},
      warning: [],
      versions: [],
      showModal: {
        checkVersion: false
      },
      criticalChanges: 0,
      dataForm: "",
      editor: null,
      loading: false
    };
  },
  filters: {
    timeago(value) {
      return timeago(value, "en");
    }
  },
  computed: {
    ...mapState({
      categories: state => state.docs.categories.list,
      loadingCategories: state => state.docs.categories.loading
    }),
    loadingData() {
      return this.loadingCategories || this.loading;
    },
    getCategoriesDropdown() {
      const tree = this.categories
        .filter(x => !x.category_id)
        .map(x => ({
          ...x,
          children: this.getChildren(this.categories, x.id)
        }));
      return this.flatternCategories(tree);
    },
    submitForm() {
      let newForm = JSON.parse(JSON.stringify(this.form));
      newForm.title = (newForm.title || "").trim();
      newForm.content = this.editor.getMarkdown();

      return newForm;
    },
    editorOptions() {
      const options = Object.assign({}, defaultOptions, this.options);
      options.initialEditType = this.mode;
      options.height = this.height;
      options.language = this.language;
      return options;
    }
  },
  created() {
    this.fetchData();
    if (!this.categories || !this.categories.length) {
      this.fetchCategories();
    }
  },
  mounted() {
    document.addEventListener("keydown", this.listenSave, false);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.listenSave, false);
  },
  methods: {
    ...mapActions({
      fetchCategories: "fetchCategories"
    }),
    initEditor() {
      this.editor = new Editor({
        el: document.getElementById(this.id),
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
        ...this.editorOptions
      });
    },
    recalcDataExport(form = this.form) {
      try {
        let newForm = JSON.parse(JSON.stringify(form));
        delete newForm.id;
        this.dataForm = JSON.stringify(newForm, function(key, value) {
          if (typeof value === "object") return JSON.stringify(value);
          return value;
        });
      } catch (error) {
        this.dataForm = null;
      }
    },
    importForm(data) {
      try {
        let form = JSON.parse(JSON.parse(data));
        // form.
        // this.form = form;
        Object.keys(form).forEach(key => {
          if (key != "id") this.form[key] = form[key];
        });
        this.recalcDataExport();
        this.OpenSuccess({ message: "Done import form data.", time: 1500 });
      } catch (error) {
        console.error(error);
        this.OpenError({
          message: "Can not import form data due to JSON Error."
        });
      }
    },
    flatternCategories(array, result, parentTitle = "") {
      if (!result) {
        result = [];
      }

      array.forEach(e => {
        let found = result.find(item => item.id == e.id);
        if (!found) {
          let title = "";
          if (!parentTitle) {
            title = e.title;
          } else {
            title = parentTitle + ` > ${e.title}`;
          }
          result.push({ id: e.id, title: title });
          if (e.children && e.children.length) {
            return this.flatternCategories(e.children, result, title);
          }
        }
      });

      return result;
    },
    getChildren(array, id) {
      return array
        .filter(x => x.category_id == id)
        .map(x => ({
          ...x,
          children: this.getChildren(array, x.id)
        }));
    },
    fetchData() {
      let articleId = this.articleId;
      this.OpenLoading();
      this.loading = true;
      docsAPI
        .getArticle(articleId)
        .then(res => {
          this.CloseLoading();
          this.form = res.data.article;
          this.recalcDataExport();
          this.setBackup();
          this.loading = false;
          this.initEditor();
          this.editor.setMarkdown(this.form.content);
        })
        .catch(err => {
          console.log(err);
          this.$router.push({
            name: "Docs"
          });
        });
    },
    ...mapMutations({
      OpenLoading: "OpenLoading",
      CloseLoading: "CloseLoading",
      OpenError: "OpenError",
      OpenSuccess: "OpenSuccess"
    }),
    listenSave() {
      if (
        (window.navigator.platform.match("Mac")
          ? event.metaKey
          : event.ctrlKey) &&
        event.keyCode == 83
      ) {
        event.preventDefault();
        // Process the event here (such as click on submit button)
        this.updateArticle();
        return false;
      }
    },
    countChange() {
      let codeField = [
        "template",
        "data",
        "libs",
        "script",
        "settings",
        "style"
      ];
      let changedField = [];
      codeField.forEach(fieldName => {
        if (this.form[fieldName] != this.cacheForm[fieldName]) {
          changedField.push(fieldName);
        }
      });
      this.criticalChanges = changedField;
      return changedField;
    },
    setBackup() {
      console.log(this.form);
      this.cacheForm = JSON.parse(JSON.stringify(this.form));
    },
    updateArticle() {
      if (this.isOlder) return;
      // return;
      let changes = this.countChange();
      // console.warn({changes, fV: this.form.version, oV: this.cacheForm.version})
      if (changes.length == 0 || this.form.version != this.cacheForm.version) {
        // console.warn("UPDATE")
        this.doUpdate(this.submitForm);
      } else {
        // console.warn("CREATE NEW");
        this.showModal.checkVersion = true;
      }
    },
    doUpdate(form) {
      this.OpenLoading();
      docsAPI
        .updateArticle(this.form.id, form)
        .then(res => {
          this.CloseLoading();
          this.form = res.data.article;
          // this.UpdateArticle(res.data.article);
          this.setBackup();
          this.OpenSuccess({
            message: "Update Article Successfully!",
            time: 500
          });
          // this.$router.push({
          //   name: "Edit-Article",
          //   params: { articleId: this.form.id }
          // });
        })
        .catch(err => {
          this.OpenError({ message: err });
        });
      this.closeModal();
    },
    closeModal() {
      this.showModal.checkVersion = false;
    }
  },
  watch: {
    form: {
      deep: true,
      handler(newV) {
        this.recalcDataExport(newV);
      }
    },
    articleId(newV, oldV) {
      if (newV !== oldV) {
        if (newV) {
          this.fetchData();
        } else {
          this.form = null;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.back-menu {
  height: 50px !important;
  float: none !important;
  a {
    float: left !important;
  }
}
</style>
