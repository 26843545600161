<template>
  <div class="gf_flex gf_justify-c-between">
    <ArticleEditor :articleId="$route.params.articleId" width="full" />
  </div>
</template>

<script>
import ArticleEditor from "@/pages/docs/ArticleEditor.vue";
export default {
  name: "Edit-Article",
  components: {
    ArticleEditor
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
  watch: {}
};
</script>
